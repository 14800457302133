<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="分组名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="name">
              <a-input v-model="form.group_name" placeholder="请输入分组名称..." />
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="分组状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
              <a-select v-model="form.status" placeholder="请选择分组状态..." :options='status'>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">
              
              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加分组
      </a-button>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.group_id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 状态单元格 -->
        <template slot="status" slot-scope="data,record">
          <a-tag :color="record.status=='active'?'green':'red'">
              {{record.status|statusFilter}}
          </a-tag>
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>

                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>
  
                <a-popconfirm v-if="data.status=='active'" title="是否禁用此分组?" @confirm="() => onDisable(data)">
                  <a-button size="small" type="danger">禁用</a-button type="primary" >
                </a-popconfirm>
                <a-popconfirm v-else title="是否启用此分组?" @confirm="() => onEnable(data)">
                  <a-button size="small" type="primary" class="greenBtn">启用</a-button type="primary">
                </a-popconfirm>

              </span>

        </template>
      </a-table>
    </div>

  <!-- 修改帐号信息模态框 -->
  <a-modal

      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="saveGroup"
      @cancel="cancelGroup"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="group"
        :model="groupForm"
        :rules="groupFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >
        <a-form-model-item label="分组名称" prop="group_name">
          <a-input v-model="groupForm.group_name" placeholder="请输入分组名称..."/>
        </a-form-model-item>

        <a-form-model-item label="用户名" prop="user_id">
          <a-select v-model="groupForm.user_id" placeholder="请选择用户ID..." :options='userList'>

          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status" v-if="title=='编辑分组'">
          <a-select v-model="groupForm.status" placeholder="请选择用户状态..." :options='status'>

          </a-select>
        </a-form-model-item>
      </a-form-model>
  </a-modal>

  </div>
</template>
<script>
import {getGroupList,editGroup,addGroup,deleteGroup} from "@/axios/group.js"
import {getAllUserList} from "@/axios/user.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述
  {
    align:'center',
    title: '分组名称',
    dataIndex: 'group_name',
  },
 
  {
    align:'center',
    title: '用户名',
    dataIndex: 'username',
  },
  {
    align:'center',
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status', },
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        active: '启用',
        deleted: '禁用',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      status: [
        {
            value: '',
            label: '全部'
        },
        {
            value: 'active',
            label: '启用'
        },
        {
            value: 'deleted',
            label: '禁用'
        },
      ],     

      form: {
        group_name: '',
        status: '',
      },

      data: [],//数据
      selectedRowKeys: [],//选择的表格
      pagination: {
        showQuickJumper:true,//是否快速跳转某页
        showSizeChanger:true,//是否可以改变条数
        pageSizeOptions:['10', '20', '50', '100','200'],
        showTotal:(total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
      },//分页器
      loading: false,//是否加载中
      btnLoading:false,
      columns,//列描述
      tableSize:'default',//表格尺寸

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      groupForm:{
        group_name:'',
        group_id:'',
        user_id:'',
        status:'',
      },
      groupFormRules: {
        group_name: [
          { required: true, message: '请输入分组名', trigger: 'blur' },
        ],
        user_id: [
          { required: true, message: '请选择用户', trigger: 'change' },
        ],
        status: [
          { required: true, message: '请选择用户状态', trigger: 'change' },
        ],
      },

      title:'编辑分组',
      userList:[],//用户分组，用来下拉框选择用户使用

    }
    
  },
  mounted() {
    let that=this
    this.fetch();
    getAllUserList().then((data)=>{
      that.userList=data.data.map((item)=>{
        return{
          value:item.id,
          label:item.username
        }
      })
      console.log(that.userList,'用户列表')
    });
    
  },
  methods:{
      search(){//搜索
        this.pagination.current =1;
        this.fetch({page:1,page_size:this.pagination.pageSize});
      },
      reset(formName){
        this.$refs[formName].resetFields();
        this.pagination.current =1;
        this.fetch({page:1,page_size:this.pagination.pageSize});
      },
      onSelectChange(selectedRowKeys) {//选择表格
        this.selectedRowKeys = selectedRowKeys;
      },

      start() {//下载
        console.log("导出",this.selectedRowKeys)
        // ajax request after empty completing
        setTimeout(() => {
          this.selectedRowKeys = [];
        }, 1000);
      },

      handleTableChange(pagination, filters, sorter) {
        //pagination:分页信息，filters：筛选信息，sorter：排序信息
        this.pagination = { ...pagination };
        console.log(this.pagination,pagination)
        this.fetch({
          page_size: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      },

      async fetch (params = {}) {
        this.loading = true;
        let data=await getGroupList({page_size: 10,...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.pagination = pagination;
      },
      
      //添加用户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加分组'
      },

      //禁用分组
      async onDisable(data){
        data.status='deleted'
        let resule=await editGroup({...data});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //启用分组
      async onEnable(data){
        data.status='active'
        let resule=await editGroup({...data});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //编辑保存用户数据
      onEdit(data){
        console.log(data)
        this.title='编辑分组'
        this.groupForm={...data}

        console.log(this.groupForm,'data')
        this.editModalVisible=true
      },
      saveGroup(){
        this.$refs.group.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title=='添加分组'){
            let{group_name,user_id}=this.groupForm
            resule=await addGroup({group_name,user_id});
          }else{
            resule=await editGroup({...this.groupForm});
          }
          
          if(resule.status==1){
            console.log(resule)
            this.$message.success(resule.errmsg);
            this.editModalVisible = false;
            this.confirmLoading = false;
            this.$refs.group.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.errmsg);
            this.confirmLoading = false;
          }

        } else {
          return false;
        }
      });


      },
      cancelGroup(){
        this.$refs.group.resetFields();
        this.editModalVisible = false;
      },
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
</style>